const locationMarkList = [
  {
    pos: [118.4512647, 28.95629038],
    type: 'image',
    name: '航道起点',
    anchor: 'bottom-left',
    images: [
      {
        class: 'changshan-qizi',
        name: 'changshan/旗子@1x.png'
      }
    ],
    info: {
      content: '航道起点',
      anchor: 'bottom-center',
      color: 'bg_green'
    }
  },
  {
    pos: [118.4716102, 28.93990571],
    type: 'image',
    name: '东乡村锚地',
    title: '东乡村锚地',
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/锚地.png'
      }
    ],
    info: {
      content: '东乡村锚地',
      anchor: 'bottom-center',
      color: 'bg_green_img',
      class: 'textCenter',
      images: [
        {
          class: 'img-sign',
          name: 'changshan/5-东乡村锚地.jpg'
        }
      ]
    }
  },
  {
    pos: [118.4856559, 28.9421272],
    type: 'marker',
    name: '白虎滩三集中场站',
    info: {
      content: '白虎滩三集中场站',
      anchor: 'bottom-center',
      color: 'bg_green_img',
      class: 'textCenter paddding-lr',
      images: [
        {
          class: 'changshan-img',
          name: 'changshan/钢筋（钢构件）加工厂@1x.png'
        },
        {
          class: 'changshan-img',
          name: 'changshan/混凝土拌合站@1x.png',
          elementText: '<br/>'
        },
        {
          class: 'changshan-img',
          name: 'changshan/石料场.png'
        }
        // {
        //   class: 'changshan-img',
        //   name: 'changshan/变压器@1x.png'
        // }
      ]
    }
  },
  // [118.6866932, 28.94198727]
  { type: 'image', title: '', pos: [118.484382, 28.93488369], images: [{ name: 'camera.png', class: 'camera90' }], url: 'http://183.248.89.235:8090/test/allchannel.html' },
  {
    pos: [118.479382, 28.93488369],
    type: 'image',
    name: '白虎滩枢纽',
    title: '白虎滩枢纽',
    isPostMsg: true,
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/枢纽.png'
      }
    ],
    info: {
      content: '白虎滩枢纽',
      anchor: 'bottom-center',
      color: 'bg_green_img',
      class: 'textCenter',
      images: [
        {
          class: 'img-sign',
          name: 'changshan/1-白虎滩枢纽-1.jpg'
        }
      ]
    }
  },
  {
    pos: [118.5054325, 28.93161942],
    type: 'image',
    name: '辉埠镇人民政府',
    title: '辉埠镇人民政府',
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/地方政府.png'
      }
    ]
  },
  {
    pos: [118.5106896, 28.90150055],
    type: 'image',
    name: '常山县人民政府',
    title: '常山县人民政府',
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/地方政府.png'
      }
    ]
  },
  {
    pos: [118.4899952, 28.92500286],
    type: 'image',
    name: '徐村大桥',
    title: '徐村大桥',
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/桥梁.png'
      }
    ],
    info: {
      content: '徐村大桥',
      anchor: 'bottom-center',
      color: 'bg_green_img',
      class: 'textCenter',
      images: [
        {
          class: 'img-sign',
          name: 'changshan/10- 徐村大桥.jpg'
        }
      ]
    }
  },
  {
    pos: [118.510268, 28.90945836],
    type: 'image',
    name: '常山锚地',
    title: '常山锚地',
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/锚地.png'
      }
    ],
    info: {
      content: '常山锚地',
      anchor: 'bottom-center',
      color: 'bg_green_img',
      class: 'textCenter',
      images: [
        {
          class: 'img-sign',
          name: 'changshan/6-常山锚地.jpg'
        }
      ]
    }
  },
  {
    pos: [118.5358099, 28.89348324],
    type: 'image',
    name: '富足山大桥',
    title: '富足山大桥',
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/桥梁.png'
      }
    ],
    info: {
      content: '富足山大桥',
      anchor: 'bottom-center',
      color: 'bg_green_img',
      class: 'textCenter',
      images: [
        {
          class: 'img-sign',
          name: 'changshan/富足山大桥.png'
        }
      ]
    }
  },
  {
    pos: [118.536431, 28.89231685],
    type: 'image',
    name: '天马枢纽',
    title: '天马枢纽',
    isPostMsg: true,
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/枢纽.png'
      }
    ],
    info: {
      content: '天马枢纽',
      anchor: 'bottom-center',
      color: 'bg_green_img',
      class: 'textCenter',
      images: [
        {
          class: 'img-sign',
          name: 'changshan/2-天马枢纽-1.jpg'
        }
      ]
    }
  },
  {
    pos: [118.5841322, 28.90263378],
    type: 'image',
    name: '低铺村锚地',
    title: '低铺村锚地',
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/锚地.png'
      }
    ],
    info: {
      content: '低铺村锚地',
      anchor: 'bottom-center',
      color: 'bg_green_img',
      class: 'textCenter',
      images: [
        {
          class: 'img-sign',
          name: 'changshan/7-低铺村锚地.jpg'
        }
      ]
    }
  },
  {
    pos: [118.5892668, 28.9046017],
    type: 'image',
    name: '江家村服务区',
    title: '江家村服务区',
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'center',
    images: [
      {
        class: 'changshan-img',
        name: 'changshan/服务区@1x.png'
      }
    ],
    info: {
      content: '江家村服务区',
      anchor: 'bottom-center',
      color: 'bg_green_img',
      class: 'textCenter',
      images: [
        {
          class: 'img-sign',
          name: 'changshan/8-江家村服务区.jpg'
        }
      ]
    }
  },
  {
    pos: [118.5956278, 28.9041712],
    type: 'marker',
    name: '青石三集中场地',
    info: {
      content: '青石三集中场地',
      anchor: 'bottom-center',
      color: 'bg_green_img',
      class: 'textCenter paddding-lr',
      images: [
        {
          class: 'changshan-img',
          name: 'changshan/钢筋（钢构件）加工厂@1x.png'
        },
        {
          class: 'changshan-img',
          name: 'changshan/混凝土拌合站@1x.png',
          elementText: '<br/>'
        },
        {
          class: 'changshan-img',
          name: 'changshan/石料场.png'
        },
        // {
        //   class: 'changshan-img',
        //   name: 'changshan/变压器@1x.png'
        // },
        {
          class: 'changshan-img',
          name: 'changshan/预制梁场@1x.png'
        }
      ]
    }
  },
  {
    pos: [118.5948821, 28.90033875],
    type: 'image',
    name: '青石镇政府',
    title: '青石镇政府',
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/地方政府.png'
      }
    ]
  },
  {
    pos: [118.5836846, 28.90096572],
    type: 'image',
    name: '总承包部',
    title: '总承包部',
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/总承包部.png'
      }
    ],
    info: {
      content: '总承包部',
      anchor: 'bottom-center',
      color: 'red'
    }
  },
  {
    pos: [118.6022373, 28.9150778],
    type: 'image',
    name: '阁底枢纽',
    title: '阁底枢纽',
    isPostMsg: true,
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/枢纽.png'
      }
    ],
    info: {
      content: '阁底枢纽',
      anchor: 'bottom-center',
      color: 'bg_green_img',
      class: 'textCenter',
      images: [
        {
          class: 'img-sign',
          name: 'changshan/3-阁底枢纽-1.jpg'
        }
      ]
    }
  },
  {
    pos: [118.6722199, 28.93380446],
    type: 'image',
    name: '樊村锚地',
    title: '樊村锚地',
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/锚地.png'
      }
    ],
    info: {
      content: '樊村锚地',
      anchor: 'bottom-center',
      color: 'bg_green_img',
      class: 'textCenter',
      images: [
        {
          class: 'img-sign',
          name: 'changshan/9-樊村锚地.jpg'
        }
      ]
    }
  },
  {
    pos: [118.6810229, 28.93440585],
    type: 'image',
    name: '招贤人行桥',
    title: '招贤人行桥',
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/桥梁.png'
      }
    ],
    info: {
      content: '招贤人行桥',
      color: 'bg_green_img',
      class: 'textCenter',
      images: [
        {
          class: 'img-sign',
          name: 'changshan/11-招贤人行桥.jpg'
        }
      ]
    }
  },
  {
    pos: [118.6869616, 28.93742115],
    type: 'image',
    name: '招贤枢纽闸首桥',
    title: '招贤枢纽闸首桥',
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/桥梁.png'
      }
    ],
    info: {
      content: '招贤枢纽闸首桥',
      anchor: 'bottom-center',
      color: 'bg_green_img',
      class: 'textCenter',
      images: [
        {
          class: 'img-sign',
          name: 'changshan/4-招贤枢纽-1.jpg'
        }
      ]
    }
  },
  {
    pos: [118.6872735, 28.9391037],
    type: 'image',
    name: '招贤枢纽',
    title: '招贤枢纽',
    isPostMsg: true,
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/枢纽.png'
      }
    ],
    info: {
      content: '招贤枢纽',
      anchor: 'bottom-center',
      color: 'bg_green_img',
      class: 'textCenter',
      images: [
        {
          class: 'img-sign',
          name: 'changshan/4-招贤枢纽-1.jpg'
        }
      ]
    }
  },
  {
    pos: [118.6866932, 28.94198727],
    type: 'image',
    name: '工区驻地',
    title: '工区驻地',
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/工区驻地.png'
      }
    ]
  },
  // {
  //   pos: [118.6866932, 28.94198727],
  //   type: 'image',
  //   name: '变压器',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/变压器@1x.png'
  //     }
  //   ]
  // },
  {
    pos: [118.7308742, 28.94858269],
    type: 'image',
    name: '航道终点',
    anchor: 'bottom-left',
    images: [
      {
        class: 'changshan-qizi',
        name: 'changshan/旗子@1x.png'
      }
    ],
    info: {
      content: '航道终点',
      anchor: 'bottom-center',
      color: 'bg_green'
    }
  },
  {
    pos: [118.6829267, 28.93314038],
    type: 'image',
    name: '招贤镇人民政府',
    title: '招贤镇人民政府',
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/地方政府.png'
      }
    ]
  },
  // {
  //   pos: [118.7026147, 28.9429364],
  //   type: 'image',
  //   name: '工区项目部',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/工区项目部@1x.png'
  //     }
  //   ]
  // },
  // {
  //   pos: [118.6995431, 28.94355372],
  //   type: 'image',
  //   name: '弃渣场',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/弃渣场@1x.png'
  //     }
  //   ]
  // },
  // {
  //   pos: [118.6998118, 28.94359176],
  //   type: 'image',
  //   name: '空压站',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/空压站@1x.png'
  //     }
  //   ]
  // },
  // {
  //   pos: [118.699986, 28.943655],
  //   type: 'image',
  //   name: '水泥、五金等材料仓库',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/水泥、五金等材料仓库@1x.png'
  //     }
  //   ]
  // },
  // {
  //   pos: [118.700117, 28.94368],
  //   type: 'image',
  //   name: '机械停放及维修场',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/机械停放及维修厂等@1x.png'
  //     }
  //   ]
  // },
  // {
  //   pos: [118.6993173, 28.94348756],
  //   type: 'image',
  //   name: '金结、机电拼装场',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-img',
  //       name: 'changshan/金结、机电拼装场@1x.png'
  //     }
  //   ]
  // },
  {
    pos: [118.6875097, 28.93440583],
    type: 'image',
    name: '工区驻地',
    title: '工区驻地',
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/工区驻地.png'
      }
    ]
  },
  // {
  //   pos: [118.6875097, 28.93440583],
  //   type: 'image',
  //   name: '变压器',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/变压器@1x.png'
  //     }
  //   ]
  // },
  {
    pos: [118.6037532, 28.90688897],
    type: 'image',
    name: '工区驻地',
    title: '工区驻地',
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/工区驻地.png'
      }
    ]
  },
  // {
  //   pos: [118.6037532, 28.90688897],
  //   type: 'image',
  //   name: '变压器',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/变压器@1x.png'
  //     }
  //   ]
  // },
  {
    pos: [118.5972642, 28.9163635],
    type: 'image',
    name: '工区驻地',
    title: '工区驻地',
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/工区驻地.png'
      }
    ]
  },
  // {
  //   pos: [118.5972642, 28.9163635],
  //   type: 'image',
  //   name: '变压器',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/变压器@1x.png'
  //     }
  //   ]
  // },
  // {
  //   pos: [118.5980043, 28.9172092],
  //   type: 'image',
  //   name: '工区项目部',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/工区项目部@1x.png'
  //     }
  //   ]
  // },
  // {
  //   pos: [118.5981545, 28.91685203],
  //   type: 'image',
  //   name: '空压站',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/空压站@1x.png'
  //     }
  //   ]
  // },
  // {
  //   pos: [118.598351, 28.916989],
  //   type: 'image',
  //   name: '水泥、五金等材料仓库',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/水泥、五金等材料仓库@1x.png'
  //     }
  //   ]
  // },
  // {
  //   pos: [118.59824, 28.916934],
  //   type: 'image',
  //   name: '机械停放及维修场',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/机械停放及维修厂等@1x.png'
  //     }
  //   ]
  // },
  // {
  //   pos: [118.5679264, 28.90887053],
  //   type: 'image',
  //   name: '弃渣场',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/弃渣场@1x.png'
  //     }
  //   ]
  // },
  {
    pos: [118.5335106, 28.89077975],
    type: 'image',
    name: '工区驻地',
    title: '工区驻地',
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/工区驻地.png'
      }
    ]
  },
  // {
  //   pos: [118.5335106, 28.89077975],
  //   type: 'image',
  //   name: '变压器',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/变压器@1x.png'
  //     }
  //   ]
  // },
  // {
  //   pos: [118.5332754, 28.89086969],
  //   type: 'image',
  //   name: '工区项目部',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/工区项目部@1x.png'
  //     }
  //   ]
  // },
  // {
  //   pos: [118.5332326, 28.89070529],
  //   type: 'image',
  //   name: '空压站',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/空压站@1x.png'
  //     }
  //   ]
  // },
  // {
  //   pos: [118.533395, 28.890844],
  //   type: 'image',
  //   name: '水泥、五金等材料仓库',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/水泥、五金等材料仓库@1x.png'
  //     }
  //   ]
  // },
  // {
  //   pos: [118.533347, 28.890704],
  //   type: 'image',
  //   name: '机械停放及维修场',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/机械停放及维修厂等@1x.png'
  //     }
  //   ]
  // },
  // {
  //   pos: [118.5330775, 28.89073861],
  //   type: 'image',
  //   name: '金结、机电拼装场',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-img',
  //       name: 'changshan/金结、机电拼装场@1x.png'
  //     }
  //   ]
  // },
  {
    pos: [118.4678388, 28.92638829],
    type: 'image',
    name: '工区驻地',
    title: '工区驻地',
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/工区驻地.png'
      }
    ]
  },
  // {
  //   pos: [118.4678388, 28.92638829],
  //   type: 'image',
  //   name: '变压器',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/变压器@1x.png'
  //     }
  //   ]
  // },
  // {
  //   pos: [118.4740056, 28.93782288],
  //   type: 'image',
  //   name: '空压站',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/空压站@1x.png'
  //     }
  //   ]
  // },
  // {
  //   pos: [118.473412, 28.938564],
  //   type: 'image',
  //   name: '水泥、五金等材料仓库',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/水泥、五金等材料仓库@1x.png'
  //     }
  //   ]
  // },
  // {
  //   pos: [118.474039, 28.938677],
  //   type: 'image',
  //   name: '机械停放及维修场',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/机械停放及维修厂等@1x.png'
  //     }
  //   ]
  // },
  {
    pos: [118.4804805, 28.93725568],
    type: 'image',
    name: '工区驻地',
    title: '工区驻地',
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/工区驻地.png'
      }
    ]
  },
  // {
  //   pos: [118.4804805, 28.93725568],
  //   type: 'image',
  //   name: '变压器',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/变压器@1x.png'
  //     }
  //   ]
  // },
  // {
  //   pos: [118.4811114, 28.93770497],
  //   type: 'image',
  //   name: '工区项目部',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/工区项目部@1x.png'
  //     }
  //   ]
  // },
  // {
  //   pos: [118.4906429, 28.93634333],
  //   type: 'image',
  //   name: '弃渣场',
  //   anchor: 'center',
  //   images: [
  //     {
  //       class: 'changshan-qizi',
  //       name: 'changshan/弃渣场@1x.png'
  //     }
  //   ]
  // },
  {
    pos: [118.53894917, 28.89096763],
    type: 'image',
    name: '长圩大桥',
    title: '长圩大桥',
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/桥梁.png'
      }
    ]
  }
]

const roadPath = [
  [118.45126468, 28.95629038],
  [118.45777659, 28.95237138],
  [118.46721218, 28.94458983],
  [118.46767713, 28.94416265],
  [118.46817446, 28.94358123],
  [118.4686076, 28.94287545],
  [118.46894616, 28.94203636],
  [118.47067406, 28.93686834],
  [118.47100944, 28.93543138],
  [118.47238505, 28.93402144],
  [118.47431786, 28.93379374],
  [118.48127149, 28.93387066],
  [118.4864973, 28.93485778],
  [118.48651884, 28.93486285],
  [118.48686482, 28.93492133],
  [118.48733391, 28.93494582],
  [118.48825202, 28.9348257],
  [118.49069333, 28.93174577],
  [118.49047447, 28.93054579],
  [118.48957832, 28.92587336],
  [118.48943625, 28.9208718],
  [118.49037941, 28.91703224],
  [118.49456726, 28.91434835],
  [118.50009812, 28.91424719],
  [118.51096028, 28.91082618],
  [118.52075535, 28.90637954],
  [118.52516021, 28.90405694],
  [118.52997695, 28.89909919],
  [118.53304864, 28.89758682],
  [118.53636107, 28.89414696],
  [118.53933955, 28.89057651],
  [118.54344302, 28.88905684],
  [118.54978624, 28.88945642],
  [118.55617292, 28.89546394],
  [118.56041491, 28.90189007],
  [118.56626083, 28.90395733],
  [118.57044535, 28.90354683],
  [118.5831089, 28.90394061],
  [118.59361174, 28.90420715],
  [118.59687021, 28.90605326],
  [118.60065316, 28.9108272],
  [118.60440847, 28.91782914],
  [118.60825686, 28.92170618],
  [118.60921599, 28.92274524],
  [118.61195816, 28.92394441],
  [118.61689054, 28.92444031],
  [118.6198161, 28.92413213],
  [118.62348287, 28.92238235],
  [118.63241297, 28.91461442],
  [118.63787589, 28.91308929],
  [118.64460823, 28.91584796],
  [118.65038144, 28.92237325],
  [118.65802335, 28.92816727],
  [118.66312472, 28.93147491],
  [118.67760717, 28.9328601],
  [118.68528432, 28.93738042],
  [118.68930084, 28.941106],
  [118.69124098, 28.94240912],
  [118.69762696, 28.94417759],
  [118.70529109, 28.94306292],
  [118.71763336, 28.93930364],
  [118.72457929, 28.94163756],
  [118.7282693, 28.94469981],
  [118.73098034, 28.94872847]
]

const markerPathList = []

const directionMarkList = [
  {
    pos: [118.702722, 28.99247],
    type: 'image',
    title: '指北针',
    titleConfig: {
      class: 'cs-info'
    },
    anchor: 'bottom-center',
    images: [
      {
        class: 'changshan-marker',
        name: 'changshan/指北针.png'
      }
    ]
  },
  {
    pos: [118.422648, 28.989191],
    type: 'image',
    title: '辉埠',
    titleConfig: {
      class: 'arrow-info',
      direction: 'bottom'
    },
    anchor: 'middle-left',
    images: [
      {
        class: 'dy-arrow-start',
        name: 'redArrow.png'
      }
    ]
  },
  {
    pos: [118.758634, 29.00168],
    type: 'image',
    title: '双港口',
    titleConfig: {
      class: 'arrow-info',
      direction: 'top'
    },
    anchor: 'middle-left',
    images: [
      {
        class: 'dy-arrow-end',
        name: 'redArrow.png'
      }
    ]
  }
]

export default {
  locationMarkList,
  roadPath: roadPath,
  // .reverse()
  // .filter((_, index) => index % 2 !== 0)
  // .filter((_, index) => index % 2 !== 0),
  markerPathList,
  directionMarkList
}
